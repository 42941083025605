import React from "react";
import Layout from "../components/layout/layout";
import Seo from "../components/layout/seo";
import { graphql } from "gatsby";
import { Container, Box, Typography } from "@mui/material";

import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import download from "../images/download_quality.svg";
import pdf from "../images/pdf/Consent Form Template v2.docx.pdf";

let cs_theme = createTheme({
  palette: {
    primary: {
      main: "#FFA100",
    },
    secondary: {
      main: "#00C5B7",
    },
    white: "#FFFFFF",
    black: "#202026",
    purple: "#8F4FFF",
  },
  typography: {
    fontFamily: ["Red Hat Display"].join(","),
  },
});
cs_theme = responsiveFontSizes(cs_theme);

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined";

const Press = ({ data, location }) => {
  function reveal() {
    var reveals = document.querySelectorAll(".reveal");

    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;

      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }

  if (isBrowser) {
    window.addEventListener("scroll", reveal);
  }

  const siteTitle = data.site.siteMetadata.title;

  return (
    <ThemeProvider theme={cs_theme}>
      <Layout location={location} title={siteTitle}>
        <Seo title="Consent Form | Custom Surgical" />
        <Box className="consent_form">
          <Container maxWidth="lg">
            <Typography
              variant="h3"
              color={"white"}
              fontWeight="bold"
              padding={{ xs: 4, sm: 8, md: 12 }}
              style={{
                display: "flex",
                height: "60vh",
                alignItems: "flex-end",
                textShadow: "#000 1px 0 10px",
              }}
            >
              PATIENT CONSENT FORMS
            </Typography>
          </Container>
        </Box>
        <Container maxWidth="lg">
          <Typography variant="h6" paddingTop={{ xs: 3, sm: 5, md: 7 }}>
            We are always working on helping you stay compliant when using the
            MicroREC App.
          </Typography>
          <Typography variant="h6" paddingTop={{ xs: 3, sm: 3, md: 3 }}>
            Here you can find a template for obtaining consent from patients for
            the use of their confidential data.
          </Typography>

          <h2>Consent Form</h2>
          <a href={pdf} target="_blank" download>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginTop: "2em",
                marginBottom: "3em",
              }}
            >
              <img src={download} style={{ maxWidth: "100%" }}></img>
              <h5 style={{ margin: "0", paddingLeft: "1em" }}>
                Patient Consent form.pdf
              </h5>
            </div>
          </a>
        </Container>
      </Layout>
    </ThemeProvider>
  );
};

export default Press;

export const pageQuery = graphql`
  {
    site {
      siteMetadata {
        title
      }
    }
    allInfoJson {
      nodes {
        id
        date(formatString: "")
        zip {
          publicURL
        }
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
        title
        pdf {
          publicURL
        }
      }
    }
    allPicturesJson {
      nodes {
        zip {
          publicURL
        }
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allLogoJson {
      nodes {
        zip {
          publicURL
        }
        img {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
    allNewsJson {
      nodes {
        pdf {
          publicURL
        }
        img1 {
          childImageSharp {
            gatsbyImageData
          }
        }
        img2 {
          childImageSharp {
            gatsbyImageData
          }
        }
        link
        text
      }
    }
  }
`;
